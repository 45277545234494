import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { Revision } from "../@core/data/faq-data/revision";
import { io } from "socket.io-client";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FileUploaderService } from "./file-uploader.service";
import { WebsiteCrawlerService } from "./website-crawler.service";
import { environment } from "../../environments/environment";
import { UserService } from "./users.service";
import { WebsocketService } from "./websocket.service";

@Injectable({
  providedIn: "root",
})
export class QAndAService {
  faqs: any[] = [];
  currentBuildQA: any[] = [];
  private faqsSubject = new BehaviorSubject<any[]>(this.faqs);
  private currentBuildQASubject = new BehaviorSubject<any[]>(
    this.currentBuildQA
  );
  public readonly faqs$ = this.faqsSubject.asObservable();
  public readonly currentBuildQA$ = this.currentBuildQASubject.asObservable();

  //Websocket variables
  private socket;

  // Backend baseurl
  // Platformdemo
  private baseUrl = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private websocketService: WebsocketService,
  ) { }

  // Get list of Q&A pairs
  // TODO: getting custom Q&A for now. After changes to backend, this should be changed to get all Q&A pairs.
  getCustomQAs(botID: string, environmentID: string) {
    return this.http.get<any>(`${this.baseUrl}/qanda/initialize?agentID=${botID}&environmentID=${environmentID}`)
      .pipe(tap((response) => {
        if (response.documents) {
          for (let qa of response.documents) {
            // Handle each item in the array
            this.addQandA(qa);
          }
        }
        if (response.approvedFAQList) {
          this.currentBuildQA = response.approvedFAQList;
          this.currentBuildQASubject.next(this.currentBuildQA);
        }
        this.faqsSubject.next(this.faqs);
      }))
  }

  clearFaqs() {
    this.faqs = [];
  }

  getFaqsArray() {
    return this.faqs;
  }

  getKnowledgeUpdate(sourceType: string) {
    if (!this.socket) {
      this.socket = this.websocketService.getSocket();
    }

    let qaUpdateDataSub = new Subject<any>();
    this.socket.on("knowledge-update", async (data) => {
      if (data.sourceType === sourceType) {
        if (data.status === "active") {
          let temp = {
            qid: data.qid,
            question: this.faqs.find((faq) => faq.qid === data.qid).question,
            answer: this.faqs.find((faq) => faq.qid === data.qid).answer,
          };
          this.currentBuildQA.push(temp);
          this.currentBuildQASubject.next(this.currentBuildQA);
        }
        qaUpdateDataSub.next(data);
      }
    });

    return qaUpdateDataSub.asObservable();
  }

  getCustomKnowledgeCreation() {
    if (!this.socket) {
      this.socket = this.websocketService.getSocket();
    }

    this.socket.on("qa-created", async (data) => {
      this.addQA(data);
    });
  }

  getCurrentBuildQAs() {
    // Make a GET request to the backend.
    return this.http.get<any>(`${this.baseUrl}/qanda/initialize`);
  }

  eventCleanUp() {
    this.socket.off("knowledge-creation");
    this.socket.off("knowledge-update");
  }

  addQA(newFaq): void {
    this.faqs.push(newFaq);
    this.faqsSubject.next(this.faqs);
  }

  addQAs(newQAs) {
    newQAs.forEach((newQA) => {
      // Check if 'this.faqs' already contains an object with the same 'qid'
      const isDuplicate = this.faqs.some((faq) => faq.qid === newQA.qid);

      // If it's not a duplicate, add it to 'this.faqs'
      if (!isDuplicate) {
        this.faqs.push(newQA);
      }
    });
    this.faqsSubject.next(this.faqs);
  }

  updateQA(newFaq): void {
    const existingIndex = this.faqs.findIndex((faq) => faq.qid === newFaq.qid);

    this.faqs[existingIndex] = {
      ...this.faqs[existingIndex],
      ...newFaq,
    };

    this.faqsSubject.next(this.faqs);
  }

  addQandA(newFaq): void {
    const newFaqMap = new Map<string, any>();

    for (const faq of this.faqs) {
      const key = faq.qid;
      newFaqMap.set(key, faq);
    }

    // Add unique id
    const key = newFaq.qid;
    newFaqMap.set(key, newFaq);

    // Finally, convert the map back to an array.
    this.faqs = Array.from(newFaqMap.values());

    // Next, we notify all subscribers that the faqs array has been updated.
    this.faqsSubject.next(this.faqs);
  }

  createRevisionHistory(oldFaq, faq): void {
    if (!faq.revisions) {
      faq.revisions = [];
    }
    const curRevisionNum = faq.revisions.length + 1;
    let tempRevisionObj: Revision = {
      question: oldFaq.question,
      answer: oldFaq.answer,
      revisedDate: Date.now(),
      revisionNumber: curRevisionNum,
    };
    faq.revisions.push(tempRevisionObj);
    // Set lastModifiedTimeMS
    faq.curRevisionNum = curRevisionNum;
    faq.lastModifiedTimeMS = tempRevisionObj.revisedDate;
  }

  updateFaq(faq) {
    return this.http.post(`${this.baseUrl}/qanda/update`, {
      sourceId: faq.sourceId,
      qid: faq.qid,
      question: faq.question,
      answer: faq.answer,
      trainings: faq.trainings,
      revisions: faq.revisions,
      sourceType: faq.sourceType,
      curRevisionNum: faq.curRevisionNum,
    });
  }

  // Update qanda status
  updateStatus(sourceId: string, qid, sourceType: string, status: string) {
    return this.http.post(`${this.baseUrl}/qanda/update/status`, {
      sourceId: sourceId,
      qid: qid,
      status: status,
      sourceType: sourceType,
    });
  }

  addCustomQA(question, answer, trainings, botID, environmentID) {
    // Set http headers
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post(
      `${this.baseUrl}/qanda/add`,
      {
        question: question,
        answer: answer,
        trainings: trainings,
        botID: botID,
        environmentID: environmentID
      },
      httpOptions
    );
  }

  removeQALocal(qid) {
    this.faqs = this.faqs.filter((faq) => faq.qid !== qid);
    this.faqsSubject.next(this.faqs);
  }

  deleteQA(qaList: any[]) {
    return this.http.post(`${this.baseUrl}/qanda/delete`, {
      qaList: qaList,
    });
  }

  publish(activeList, botID, environmentID) {
    const temp = [...this.currentBuildQA];
    this.currentBuildQA = activeList;
    return this.http
      .post(`${this.baseUrl}/bot/build`, { activeList: activeList, agentID: botID, environmentID: environmentID })
      .pipe(
        tap(
          (response) => {
            this.currentBuildQASubject.next(this.currentBuildQA);
          },
          (error) => {
            this.currentBuildQA = temp;
            this.currentBuildQASubject.next(this.currentBuildQA);
          }
        )
      );
  }

  generateTrainingPhrases(
    question: string,
    answer: string,
    trainingPhrases: string[]
  ) {
    let body;
    if (trainingPhrases.length === 1 && trainingPhrases[0] === "") {
      body = {
        data: {
          question: question,
          answer: answer,
        },
      };
    } else {
      body = {
        data: {
          question: question,
          answer: answer,
          existing_phrase: trainingPhrases,
        },
      };
    }
    return this.http.post(`${this.baseUrl}/knowledge/training_phrases`, body);
  }
}
