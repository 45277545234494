import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Subject, forkJoin, Observable, Subscription, BehaviorSubject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import { Webpage } from "../@core/data/webpage-data/webpage";
import { WebpageContent } from "../@core/data/backend-response/webpage-content";


import { UserNotificationService } from "./user-notification.service";
import { QAndAService } from "./q-and-a.service";
import { environment } from "../../environments/environment";

import {
  NbToastrService,
  NbComponentStatus,
  NbGlobalPosition,
  NbGlobalPhysicalPosition,
} from "@nebular/theme";
import { UserService } from "./users.service";
import { WebsocketService } from "./websocket.service";
import { ChatbotService } from "./chat-bot.service";

@Injectable({
  providedIn: "root",
})
export class WebsiteCrawlerService {
  private webpagesMap: { [key: string]: Webpage } = {};
  private webpages: Webpage[] = [];
  private _webpagesSubject = new BehaviorSubject<Webpage[]>([]);

  // Base url
  private baseUrl = environment.backendUrl;

  // Processing flag
  private crawlingStatusSubject = new BehaviorSubject<boolean>(false);
  crawlingStatus$ = this.crawlingStatusSubject.asObservable();

  private socket;

  // Toaster variables
  destroyByClick = true;
  duration = 5000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;

  // Subscriptions
  private botSubscription: Subscription = null;

  constructor(
    private http: HttpClient,
    private toasterService: NbToastrService,
    private notificationService: UserNotificationService,
    private qandaService: QAndAService,
    private userService: UserService,
    private websocketService: WebsocketService
  ) { }

  getWebpages(botID: string, environmentID: string) {
    return this.http.get<any[]>(`${this.baseUrl}/website/initialize?agentID=${botID}&environmentID=${environmentID}`)
      .pipe(tap((response) => {
        for (let website of response) {
          for (let faq of website.faqs) {
            faq.sourceId = website.docid;
            faq.sourceType = "webpage";
            faq.source = website.webpageSource;
            this.qandaService.addQandA(faq);
          }
          // Update webpages map
          this.webpagesMap[website.docid] = website;
        }
        // Update webpages array
        this.webpages = Object.values(this.webpagesMap);
        // Emit the changes
        this._webpagesSubject.next(this.webpages);
      }))
  }

  websocketInit() {
    if (!this.socket) {
      this.socket = this.websocketService.getSocket();
    }

    // Start crawler knowledge update subscription
    this.socket.on("webpages", async (data) => {
      if (!this.webpagesMap[data.docid]) {
        // Webpage creation
        this.webpagesMap[data.docid] = data;
        this.notificationService.showToast(
          "success",
          "Webpage Added",
          `Webpage scraped for ${data.webpageSource ? data.webpageSource : data.docid}`
        );
      } else {
        // Webpage update
        this.webpagesMap[data.docid] = { ...this.webpagesMap[data.docid], ...data };
        if (data.faqs) {
          for (let faq of data.faqs) {
            this.qandaService.addQandA(faq);
          }
        }
      }
      this.webpages = Object.values(this.webpagesMap);
      this._webpagesSubject.next(this.webpages);
    });
  }

  getSiteMapNotification() {
    if (!this.socket) {
      this.socket = this.websocketService.getSocket();
    }

    let sitemapDataSub = new BehaviorSubject<any>({});
    let endSub = new Subject<any>();

    // Add WebSocket listener for "sitemap-notification"
    this.socket.on("sitemap-notification", (data) => {
      sitemapDataSub.next(data);
      this.notificationService.showToast(
        "success",
        "Sitemap Generation Success",
        `Sitemap for '${data.mainDomain}' is now generated.`
      );
    });

    // Ensure the subscription ends when signaled
    sitemapDataSub.pipe(takeUntil(endSub)).subscribe();

    return {
      observable: sitemapDataSub.asObservable(),
      unsubscribe: () => {
        endSub.next(true); // Emit to end the subscription
        endSub.complete(); // Cleanup endSub
        sitemapDataSub.complete(); // Cleanup sitemapDataSub
        this.socket.off("sitemap-notification"); // Remove the WebSocket listener
      },
    };
  }

  clearWebpages() {
    this.webpages = [];
    this.webpagesMap = {};
  }

  // Getter for the webpages observable
  get webpages$(): Observable<Webpage[]> {
    return this._webpagesSubject.asObservable();
  }

  updateQA(knowledgeData) {
    // Find the webpage that matches the sourceId of the knowledgeData
    const webpage = this.webpages.find(
      (webpage) => webpage.docid === knowledgeData.sourceId
    );

    if (webpage) {
      // Search for an object in the webpage's faqs array that matches the qid field of the knowledgeData object
      const existingIndex = webpage.faqs.findIndex(
        (faq) => faq.qid === knowledgeData.qid
      );

      webpage.faqs[existingIndex] = {
        ...webpage.faqs[existingIndex],
        ...knowledgeData,
      };

      // Emit changes to webpages
      this._webpagesSubject.next(this.webpages);
    }
  }

  removeWebpageQALocal(QAToRemove) {
    // Find the object in this.fileContents where the docid matches knowledgeData.sourceId
    const webpage = this.webpages.find(
      (webpage) => webpage.docid === QAToRemove.sourceId
    );

    if (webpage) {
      // If a match is found, remove the object from the faqs array where the qid field equals QAToRemove.qid
      const index = webpage.faqs.findIndex((faq) => faq.qid === QAToRemove.qid);
      if (index !== -1) {
        // If the object with key QAToRemove.qid exists, delete it
        webpage.faqs.splice(index, 1);
        this._webpagesSubject.next(this.webpages);
      }
    }
    return;
  }

  getWebpageContent(dataLocation: string) {
    const payload = { dataLocation }; // Wrap into an object
    return this.http.post<WebpageContent>(
      `${this.baseUrl}/knowledge/content`,
      payload
    );
  }

  deleteWebpage(
    webpage: any,
    deleteDocFaqs: boolean,
    deleteDocLlm: boolean
  ): Observable<any> {
    const docIDs = [webpage.docid];
    const randomUUID = uuidv4();
    const body = {
      id: randomUUID,
      docIDs: docIDs,
      delete_doc_faqs: deleteDocFaqs,
      delete_doc_llm: deleteDocLlm,
    };

    return this.http.post(`${this.baseUrl}/knowledge/remove`, body);
  }

  undoDelete(webpage: any): Observable<any> {
    const docIDs = [webpage.docid];
    const randomUUID = uuidv4();
    const body = {
      id: randomUUID,
      docIDs: docIDs,
      delete_doc_faqs: true,
      delete_doc_llm: false,
    };

    return this.http.post(`${this.baseUrl}/knowledge/remove/undo`, body);
  }

  submitUrl(urls: String[], productIngests: Boolean[], tableIngests: Boolean[], botID: string, environmentID: string) {
    if (urls.length !== productIngests.length) {
      throw new Error('URLs array and productIngests array have different lengths');
    }
    // Set crawling status to true
    this.crawlingStatusSubject.next(true);

    const requests = urls.map((url, index) => this.http.post(`${this.baseUrl}/services/scrape`, {
      companyWebsite: url,
      botID: botID,
      environmentID: environmentID,
      ingestProduct: productIngests[index],
      ingestTables: tableIngests[index]
    }, { responseType: "text" }));

    forkJoin(requests).subscribe(
      (responses) => {
        this.crawlingStatusSubject.next(false);
        this.notificationService.showToast(
          "primary",
          "Website Scraping Submitted",
          `Website is being scraped. It may take a few minutes.`
        );
      },
      (error) => {
        this.crawlingStatusSubject.next(false);
        this.notificationService.showToast(
          "danger",
          "Something Went Wrong!",
          `${error}`
        );
        console.error('Error occurred while making webpage crawl request:', error);
      }
    );
  }

  submitAdvanceScraping(urls: string[], productIngests: boolean[], scrapeOption: string[], excludeSitesArray: string[], botID: string) {
    this.crawlingStatusSubject.next(true);

    const requests = urls.map((url, index) => this.http.post(`${this.baseUrl}/services/scrape`, {
      companyWebsite: url,
      botID: botID,
      ingestProduct: productIngests[index],
      scrapeOption: scrapeOption[index],
      savingDirectory: 'website_scraper_assets',
      excludeSites: excludeSitesArray
    }, { responseType: "text" }));

    forkJoin(requests).subscribe({
      next: (res) => {
        this.crawlingStatusSubject.next(false);
        this.notificationService.showToast(
          "success",
          "Advanced Crawling",
          `Advanced website crawling has started`
        );
      },
      error: (err) => {
        this.crawlingStatusSubject.next(false);
        this.notificationService.showToast(
          "danger",
          "Advanced Crawling Failed",
          `${err}`
        );
        console.error('Error occurred while making advanced webpage crawl request:', err);
      }
    });
  }

  queryFirestore(collection: string, subdomain: string, maindomain: string, level: number, page: number): Observable<any> {
    const apiUrl = this.baseUrl + '/website/query/firestore';
    const params = {
      collection,
      subdomain,
      maindomain,
      level: level.toString(),
      page: page.toString(),
    };

    // Serialize params manually for debugging
    const queryString = new URLSearchParams(params).toString();
    const fullUrl = `${apiUrl}?${queryString}`;

    return this.http.get(fullUrl);
  }

  getListOfGeneratedSiteMaps(page: number, pageSize: number): Observable<any> {
    const apiUrl = this.baseUrl + '/website/query/sitemap';
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());

    return this.http.get<any>(apiUrl, { params });
  }

  siteMapGeneration(siteUrl: string, maxDepth: number): Observable<any> {
    const apiUrl = this.baseUrl + '/website/generate-sitemap';
    const requestBody = { siteUrl, maxDepth };
    return this.http.post(apiUrl, requestBody);
  }

  eventCleanUp() {
    this.socket.off("webpages");
    this.socket.off("sitemap-notification");
  }
}
