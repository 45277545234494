export const environment = {
  projectID: "platformdemo-374618",
  firebaseConfig: {
    apiKey: "AIzaSyBTSd1Ou6IsEf-0aohzB2n0VD0plKOBc30",
    authDomain: "platformdemo-374618.firebaseapp.com",
    databaseURL: "https://platformdemo-374618-default-rtdb.firebaseio.com",
    projectId: "platformdemo-374618",
    storageBucket: "platformdemo-374618.appspot.com",
    messagingSenderId: "71054556014",
    appId: "1:71054556014:web:0a9f05fc46bf4496971f7f",
    measurementId: "G-YDPPXNZY6M",
  },
  backendUrl: "https://va-hub-server-gateway-v1-0-0-wn41qb2.ue.gateway.dev",
  websocketUrl: "https://websocket-proxy-masidcteuq-pd.a.run.app",
  socketIOConnectionUrl: "https://agent-chat-backend-dzg6tttmya-pd.a.run.app",
  widgetPath: "../../../assets/widget/platformdemo/index.html",
  widgetScript: `<script async id="chatbot-script" src="https://storage.googleapis.com/chat-widget-integration-script/chatWidgetIntegration.js"></script>`,
  dialogflowBotPath: "http://localhost:3000",
  botConfigBucket: "vahub-chatwidget"
};