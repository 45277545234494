import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject, from, Observable, Subscription, BehaviorSubject } from "rxjs";
import { switchMap, takeUntil, tap } from "rxjs/operators";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import { environment } from "../../environments/environment";

import {
  NbToastrService,
  NbComponentStatus,
  NbGlobalPosition,
  NbGlobalPhysicalPosition,
} from "@nebular/theme";

import { QAndAService } from "./q-and-a.service";
import { FileContent } from "../@core/data/backend-response/file-content";
import { UserService } from "./users.service";
import { WebsocketService } from "./websocket.service";
import { Router } from "@angular/router";
import { UserNotificationService } from "./user-notification.service";

@Injectable({
  providedIn: "root",
})
export class FileUploaderService {
  // Main local array containing all uploaded files
  private fileContents: any[] = [];
  private fileContentsMap: any = {};
  private fileTags: string[] = [];

  // Main array behaviour subject for multicasting updates to subscribers
  private fileContentsSubject = new BehaviorSubject<any[]>([]);
  fileContentsObservable$: Observable<any[]> =
    this.fileContentsSubject.asObservable();

  private curFile: any;

  // Sidebar variable
  private compactedSubject = new BehaviorSubject<boolean>(false);
  compacted$ = this.compactedSubject.asObservable();

  // Filter variable
  private dateRangeSubject = new BehaviorSubject<boolean>(false);
  dateSelected$ = this.dateRangeSubject.asObservable();

  // Backend baseurl
  private baseUrl = environment.backendUrl;
  private websocketUrl = environment.websocketUrl;

  // Processing flag
  private uploading: boolean = false;
  private uploadingSubject = new BehaviorSubject<boolean>(false);
  uploading$ = this.uploadingSubject.asObservable();

  // Toaster variables
  destroyByClick = true;
  duration = 5000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;

  // Websocket
  private socket;

  constructor(
    private http: HttpClient,
    private qAndAService: QAndAService,
    private websocketService: WebsocketService,
    private notificationService: UserNotificationService
  ) { }

  getFiles(botID: string, environmentID: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/file/initialize?agentID=${botID}&environmentID=${environmentID}`)
      .pipe(tap((response) => {
        for (let file of response) {
          this.addFile(file);
          if (file.tags) {
            this.addToAllTags(file.tags);
          }
          // Add faq to the faqs list in qanda service
          for (const faq of file.faqs) {
            // Add sourceId
            faq.sourceId = file.docid;
            this.qAndAService.addQandA(faq);
          }
        }
        this.fileContentsSubject.next(this.fileContents);
      }));
  }

  websocketInit() {
    if (!this.socket) {
      this.socket = this.websocketService.getSocket();
    }

    // Start crawler knowledge update subscription
    this.socket.on("files", async (data) => {
      if (!this.fileContentsMap[data.docid]) {
        // Webpage creation
        this.fileContentsMap[data.docid] = data;
        this.notificationService.showToast(
          "success",
          "File Added",
          `File uploaded for ${data.fileSource ? data.fileSource : data.docid}`
        );
      } else {
        // Webpage update
        this.fileContentsMap[data.docid] = { ...this.fileContentsMap[data.docid], ...data };
        if (data.faqs) {
          for (let faq of data.faqs) {
            this.qAndAService.addQandA(faq);
          }
        }
      }
      this.fileContents = Object.values(this.fileContentsMap);
      this.fileContentsSubject.next(this.fileContents);
    });
  }

  clearFiles() {
    this.fileContents = [];
    this.fileContentsMap = {};
  }

  // StartUploadProcess
  upload(numFiles) {
    this.uploadingSubject.next(true);

    // Once the file is uploaded, subscribe to get the processed file result.
    let { observable, unsubscribe } = this.getProcessedFile();

    observable.subscribe((data) => {
      // Add file to fileContents
      this.addFile(data);

      numFiles--;

      if (numFiles <= 0) {
        // Unsubscribe
        unsubscribe();
        // Set uploading to false
        this.uploadingSubject.next(false);
      }
    });
  }

  getProcessedFile() {
    if (!this.socket) {
      this.socket = this.websocketService.getSocket();
    }

    let fileDataSub = new Subject<any>();
    let endSub = new Subject<any>();

    this.socket.on("file processed", async (data) => {
      // Emit file data
      fileDataSub.next(data);
    });

    // Listen for the end of the subscription to remove the event listener
    fileDataSub.pipe(takeUntil(endSub)).subscribe();

    return {
      observable: fileDataSub.asObservable(),
      unsubscribe: () => {
        endSub.next(true); // Emit to end the subscription
        this.socket.off("file processed"); // Remove the event listener
      },
    };
  }

  getKnowledgeGeneration() {
    if (!this.socket) {
      this.socket = this.websocketService.getSocket();
    }

    this.socket.on("qa-generated", async (data) => {
      this.fileContents.find((file) => file.docid === data.docId).faqs =
        data.body;
      this.fileContents.find(
        (file) => file.docid === data.docId
      ).qaGenerationStatus = "Generated";
      this.fileContents.find((file) => file.docid === data.docId).status =
        "active";
      this.fileContents.find((file) => file.docid === data.docId).llm_status =
        "Pending Addition";
      this.qAndAService.addQAs(data.body);
      this.fileContentsSubject.next(this.fileContents);
    });
  }

  getFileDeletionUpdate() {
    if (!this.socket) {
      this.socket = this.websocketService.getSocket();
    }

    let fileDataSub = new Subject<any>();
    let endSub = new Subject<any>();

    this.socket.on("file-deletion-update", async (data) => {
      fileDataSub.next(data);
    });

    // Listen for the end of the subscription to remove the event listener
    fileDataSub.pipe(takeUntil(endSub)).subscribe();

    return {
      observable: fileDataSub.asObservable(),
      unsubscribe: () => {
        endSub.next(true); // Emit to end the subscription
        this.socket.off("file-deletion-update"); // Remove the event listener
      },
    };
  }

  updateQA(knowledgeData) {
    // Find the webpage that matches the sourceId of the knowledgeData
    const file = this.fileContents.find(
      (file) => file.docid === knowledgeData.sourceId
    );

    if (file) {
      // Search for an object in the webpage's faqs array that matches the qid field of the knowledgeData object
      const existingIndex = file.faqs.findIndex(
        (faq) => faq.qid === knowledgeData.qid
      );

      file.faqs[existingIndex] = {
        ...file.faqs[existingIndex],
        ...knowledgeData,
      };

      // Emit changes to files
      this.fileContentsSubject.next(this.fileContents);
    }
  }

  getFileContents(): Observable<any[]> {
    return this.fileContentsObservable$;
  }

  getFileAtIndex(index: number): any[] {
    return this.fileContents[index];
  }

  getFileContent(dataLocation: string) {
    const payload = { dataLocation }; // Wrap into an object
    return this.http.post<FileContent>(
      `${this.baseUrl}/knowledge/content`,
      payload
    );
  }

  addFile(fileData) {
    // Check if there's already an element with the same 'fileSource'
    const index = this.fileContents.findIndex(
      (file) => file.fileSource === fileData.fileSource
    );

    if (index === -1) {
      this.fileContents.push(fileData);
      this.fileContentsSubject.next(this.fileContents);
    } else {
      const temp = { ...this.fileContents[index] };
      let tempRevisions = !this.fileContents[index].revisions
        ? []
        : this.fileContents[index].revisions;
      this.fileContents[index] = fileData;
      tempRevisions.push(temp);
      this.fileContents[index].revisions = tempRevisions;
      this.fileContentsSubject.next(this.fileContents);
    }
  }

  useRevisionRequest(revisionDocid, currentDocid) {
    const body = {
      revisionDocid: revisionDocid,
      currentDocid: currentDocid,
    };
    return this.http.post(`${this.baseUrl}/file/update/version`, body);
  }

  updateFileDeletionStatus(newFile) {
    const index = this.fileContents.findIndex(
      (file) => file.docid === newFile.docid
    );

    if (index !== -1) {
      this.fileContents[index].deleteStatus = newFile.deleteStatus;
      delete this.fileContents[index].processPendingDeletion;
    }

    // Emit changes to subscribers
    this.fileContentsSubject.next(this.fileContents);
  }

  removeFile(
    file: any,
    deleteDocFaqs: boolean,
    deleteDocLlm: boolean
  ): Observable<any> {
    const docIDs = [file.docid];
    const randomUUID = uuidv4();
    const body = {
      id: randomUUID,
      docIDs: docIDs,
      delete_doc_faqs: deleteDocFaqs,
      delete_doc_llm: deleteDocLlm,
    };

    return this.http.post(`${this.baseUrl}/knowledge/remove`, body);
  }

  undoDelete(file: any): Observable<any> {
    const docIDs = [file.docid];
    const randomUUID = uuidv4();
    const body = {
      id: randomUUID,
      docIDs: docIDs,
      delete_doc_faqs: true,
      delete_doc_llm: false,
    };

    return this.http.post(`${this.baseUrl}/knowledge/remove/undo`, body);
  }

  removeFileLocal(toRemove) {
    // Remove the object from this.fileContents where the docid field matches data.docid
    this.fileContents = this.fileContents.filter(
      (file) => file.docid !== toRemove.docid
    );

    // Emit changes to subscribers
    this.fileContentsSubject.next(this.fileContents);
  }

  removeFileQALocal(QAToRemove) {
    // Find the object in this.fileContents where the docid matches knowledgeData.sourceId
    const fileContent = this.fileContents.find(
      (file) => file.docid === QAToRemove.sourceId
    );

    if (fileContent) {
      // If a match is found, remove the object from the faqs array where the qid field equals QAToRemove.qid
      const index = fileContent.faqs.findIndex(
        (faq) => faq.qid === QAToRemove.qid
      );
      if (index !== -1) {
        // If the object with key QAToRemove.qid exists, delete it
        fileContent.faqs.splice(index, 1);
        this.fileContentsSubject.next(this.fileContents);
      }
    }
    return;
  }

  addTags(docid, tags) {
    const body = {
      tags: tags,
      docid: docid,
    };

    return this.http.post(`${this.baseUrl}/file/update`, body);
  }

  addTagsLocal(docid, tags) {
    this.addToAllTags(tags);

    // Update local fileContents array
    this.fileContents.find((obj) => obj.docid === docid).tags = tags;

    // Emit new changes
    this.fileContentsSubject.next(this.fileContents);
  }

  addToAllTags(tags) {
    // Concatenate the current tags with the new tags
    const combinedTags = this.fileTags.concat(tags);

    // Remove duplicates using a Set and then convert it back to an array
    this.fileTags = Array.from(new Set(combinedTags));
  }

  getTags() {
    return this.fileTags;
  }

  checkDuplicate(fileName) {
    const index = this.fileContents.findIndex(
      (file) => file.fileSource.trim() === fileName.trim()
    );
    if (index !== -1) {
      return true;
    }
    return false;
  }

  getDownloadFileSignedUrl(fileName) {
    const requestBody = { fileName };
    return this.http.post<{ url: string }>(
      `${this.baseUrl}/file/download`,
      requestBody
    );
  }
}
