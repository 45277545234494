import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NbSidebarService } from "@nebular/theme";
import { ChatbotService } from "../../../services/chat-bot.service";

@Component({
  selector: "ngx-one-column-layout",
  styleUrls: ["./one-column.layout.scss"],
  template: `
    <nb-layout windowMode>
      <nb-layout-header class="main-header">
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar
        class="menu-sidebar"
        tag="menu-sidebar"
      >
        <ng-content class="menu-content" select="nb-menu"></ng-content>
        <h5 class="text-secondary version version-number" [hidden]="!isExpanded">
          Version: 2.0.1-alpha.2
        </h5>
      </nb-sidebar>

      <nb-layout-column class="main-column">
        <ng-content *ngIf="!processingBotChange" select="router-outlet"></ng-content>
        <div class="processing-bot-change" *ngIf="processingBotChange">
          <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
        </div>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnInit {
  isExpanded: boolean = true;
  processingBotChange: boolean = false;

  constructor(
    private router: Router,
    private sidebarService: NbSidebarService,
    private chatbotService: ChatbotService
  ) {}

  ngOnInit() {
    this.sidebarService.onToggle().subscribe((data) => {
      this.isExpanded = !this.isExpanded;
    });
    this.chatbotService.processingBotChange$.subscribe({
      next: (processing) => {
        this.processingBotChange = processing;
      }
    })
  }
}
