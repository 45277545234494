import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { lineData } from "../@core/data/chart-data/line-data";
import { barData } from "../@core/data/chart-data/bar-data";
import { pieDataItem } from "../@core/data/chart-data/pie-data";
import { radarDataItem } from "../@core/data/chart-data/radar-data";
import { CurrentMonthConversations } from "../@core/data/chart-data/current-month-conversations";

import { BehaviorSubject, Observable, Subject, forkJoin, from } from "rxjs";
import { io } from "socket.io-client";
import { EnFrYearMonthsToDate } from "../@core/data/chart-data/en-fr-year-months-to-date";
import { IntentCountMonthToDate } from "../@core/data/chart-data/intent-count-month-to-date";
import { environment } from "../../environments/environment";
import { ScriptStore } from "../@core/data/chat-widget-scripts/scripts.store";
import { Bot } from "../@core/data/bot";
import { UpdateDataService } from "./update-data.service";
import { FileUploaderService } from "./file-uploader.service";
import { WebsiteCrawlerService } from "./website-crawler.service";
import { QAndAService } from "./q-and-a.service";

declare var document: any;

@Injectable({
  providedIn: "root",
})
export class ChatbotService {
  // Backend baseurl
  private baseUrl = environment.backendUrl;

  private botList: Bot[] = [];

  private curBot: Bot;
  private curBotSubject: BehaviorSubject<Bot> = new BehaviorSubject<Bot>(null);
  curBot$: Observable<Bot> = this.curBotSubject.asObservable();

  private processingBotChangeSubject = new BehaviorSubject<boolean>(false);
  processingBotChange$ = this.processingBotChangeSubject.asObservable();

  // Scripts
  private curScript: any = {};
  private script: HTMLScriptElement | null = null;

  constructor(
    private httpClient: HttpClient,
    private dataService: UpdateDataService,
    private fileService: FileUploaderService,
    private webpageService: WebsiteCrawlerService,
    private qaService: QAndAService
  ) { }

  init() {
    this.getAllBotIDs().subscribe({
      next: (res) => {
        // Iterate through map fields
        for (const bot in res) {
          if (
            res.hasOwnProperty(bot) &&
            res[bot].agent_id &&
            res[bot].agent_name &&
            res[bot].environment_id &&
            res[bot].location
          ) {
            if (res[bot].demo) {
              this.botList.push({
                name: res[bot].agent_name,
                botID: res[bot].agent_id,
                environmentID: res[bot].environment_id,
                location: res[bot].location,
                src: res[bot].src,
                dashboardSrc: res[bot].dashboard_src,
                connectorWebhook: res[bot].connectorWebhook,
                demo: res[bot].demo
              });
              for (const key in res[bot].demo) {
                this.botList.push({
                  name: `${res[bot].agent_name}(${key})`,
                  botID: res[bot].agent_id,
                  environmentID: res[bot].demo[key]["environmentID"],
                  demoUrl: res[bot].demo[key]["url"] ? res[bot].demo[key]["url"] : null,
                  location: res[bot].location,
                  src: res[bot].src,
                  dashboardSrc: res[bot].dashboard_src,
                  connectorWebhook: res[bot].connectorWebhook,
                  isDemo: true
                });
              }
            } else {
              this.botList.push({
                name: res[bot].agent_name,
                botID: res[bot].agent_id,
                environmentID: res[bot].environment_id,
                src: res[bot].src,
                dashboardSrc: res[bot].dashboard_src,
                connectorWebhook: res[bot].connectorWebhook,
                location: res[bot].location
              });
            }
          }
        }

        this.curBot = this.botList[0];
        this.switchBot(this.curBot);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  getBotList() {
    return this.botList;
  }

  getCurBot() {
    return this.curBot;
  }

  getAllBotIDs() {
    return this.httpClient.get(`${this.baseUrl}/bot/all`);
  }

  switchBot(curBot: Bot) {
    // Spinner flag
    this.setProcessingBotChange(true);

    // Clear data
    this.fileService.clearFiles();
    this.webpageService.clearWebpages();
    this.qaService.clearFaqs();

    // unload currently loaded bot
    this.unload();

    // Save cur botID in session storage
    this.curBot = curBot;
    sessionStorage.setItem("curBot", JSON.stringify(this.curBot));

    // Set new script
    this.setCurScript();
    this.curBotSubject.next(curBot);
    this.dataService.changeFilter({
      "selectedOption": "Monthly"
    });

    this.initKnowledgeBase(curBot).subscribe({
      next: (result) => {
        console.log(`files: ${result.files}`);
        console.log(`webpages: ${result.webpages}`);
        console.log(`qa: ${result.qa}`);
        // Disable spinner flag
        this.setProcessingBotChange(false);
      },
      error: (err) => {
        console.error('Error fetching knowledge base data:', err);
      }
    });
  }

  initKnowledgeBase(curBot) {
    // Initialize files and webpages
    return forkJoin({
      files: this.fileService.getFiles(curBot.botID, curBot.environmentID),
      webpages: this.webpageService.getWebpages(curBot.botID, curBot.environmentID),
      qa: this.qaService.getCustomQAs(curBot.botID, curBot.environmentID)
    })
  }

  setCurScript() {
    this.curScript = {
      loaded: false,
      src: this.curBot.src,
      dashboardSrc: this.curBot.dashboardSrc,
      connectorWebhook: this.curBot.connectorWebhook,
      agentID: this.curBot.botID,
      projectID: environment.projectID,
      name: this.curBot.name,
      environmentID: this.curBot.environmentID,
      locationID: this.curBot.location,
    };
  }

  // Processing flag
  setProcessingBotChange(processing: boolean) {
    this.processingBotChangeSubject.next(processing);
  }

  load() {
    return this.loadScript();
  }

  loadScript() {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.curScript.loaded) {
        resolve({ script: this.curScript.name, loaded: true, status: "Already Loaded" });
      } else {
        //load script
        this.script = document.createElement("script");
        // Add timestamp to avoid cache hit
        this.script.src = `${this.curScript.dashboardSrc}?timestamp=${new Date().getTime()}`;
        this.script.id = "chatbot-script";
        this.script.async = true;
        this.script.setAttribute("environmentId", this.curScript.environmentID);
        this.script.setAttribute("locationId", this.curScript.locationID);
        this.script.setAttribute("projectId", this.curScript.projectID);
        this.script.setAttribute("agentId", this.curScript.agentID);
        this.script.setAttribute("connectorWebhook", this.curScript.connectorWebhook
        );
        //Others
        this.script.onload = () => {
          this.curScript.loaded = true;
          resolve({ script: this.curScript.name, loaded: true, status: "Loaded" });
        };
        this.script.onerror = (error: any) => {
          resolve({ script: this.curScript.name, loaded: false, status: "Loaded" });
        }
        document.getElementsByTagName("body")[0].appendChild(this.script);
      }
    });
  }

  unload() {
    this.unloadScript();
    this.cleanUpChatbot();

    const iframeElement = document.getElementById("chatBot");

    if (iframeElement) {
      iframeElement.parentNode.removeChild(iframeElement);
    }
  }

  unloadScript() {
    if (this.script) {
      this.script.remove();
      this.script = null;
      this.curScript.loaded = false;
    }
  }

  createEnvironment(botID: string, environmentID: string, location: string, name: string) {
    const body = {
      name,
      botID,
      environmentID,
      location
    };
    return this.httpClient.post(`${this.baseUrl}/bot/environment/create`, body);
  }

  uploadDemoPicture(formData: FormData) {
    return this.httpClient.post(`${this.baseUrl}/bot/demo/picture`, formData);
  }

  generateDemoPage(body: any) {
    return this.httpClient.post(`${this.baseUrl}/bot/demo/generate`, body);
  }

  getConfig(botID: string, environmentID: string) {
    return this.httpClient.get(`${this.baseUrl}/bot/config?agentID=${botID}&environmentID=${environmentID}`);
  }

  getConversationHistory(botID: string, environmentID: string) {
    return this.httpClient.get(`${this.baseUrl}/bot/conversation-history?agentID=${botID}&environmentID=${environmentID}`);
  }

  updateConfig(botID: string, environmentID: string, uiConfig: any, agentConfig: any, selectedAvatar: any) {
    let requests: Observable<any>[] = [];
    const body = {
      uiConfig,
      agentConfig
    }
    requests.push(this.httpClient.post(`${this.baseUrl}/bot/config?agentID=${botID}&environmentID=${environmentID}`, body));

    if (selectedAvatar) {
      const formData = new FormData();
      formData.append('file', selectedAvatar);
      formData.append('fileName', `${this.curBot.botID}_${this.curBot.environmentID}.png`)

      requests.push(this.httpClient.post(`${this.baseUrl}/bot/config/avatar?agentID=${botID}&environmentID=${environmentID}`, formData))
    }

    return forkJoin(requests);
  }

  createIframe(containerElement = null) {
    if (typeof window["createIframe"] === "function") {
      if (containerElement) {
        window["createIframe"](containerElement);
      } else {
        window["createIframe"]();
      }
    }
  }

  cleanUpChatbot() {
    if (typeof window["cleanUpChatbot"] === "function") {
      window["cleanUpChatbot"]();
    }
  }

  restoreStartValues() {
    if (typeof window["restoreStartValues"] === "function") {
      console.log("Restoring Chat Widget");
      window["restoreStartValues"]();
    }
  }
}
