import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
  NbMenuItem,
} from "@nebular/theme";

import { LayoutService } from "../../../@core/utils";
import { DialogService } from "primeng/dynamicdialog";
import { map, takeUntil } from "rxjs/operators";
import { Subject, Subscription, forkJoin } from "rxjs";
import { UserService } from "../../../services/users.service";
import { Router, NavigationEnd } from "@angular/router";
import { ProfileComponent } from "./profile/profile.component";
import { User } from "../../../@core/data/users";
import { MenuItem } from "primeng/api";
import {
  Languages,
  LanguagesService,
} from "../../../services/languages.service";

import { TranslateService } from "@ngx-translate/core";
import { resourceLimits } from "worker_threads";
import { AuthService } from "../../../services/auth.service";
import { ChatbotService } from "../../../services/chat-bot.service";
import { FileUploaderService } from "../../../services/file-uploader.service";
import { WebsiteCrawlerService } from "../../../services/website-crawler.service";
import { QAndAService } from "../../../services/q-and-a.service";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: User;

  displayProfile: boolean = false;

  menuItemSubscription: Subscription;

  userMenu = [
    {
      title: "Profile",
      data: { id: "profile" },
      icon: { icon: "user", pack: "fa" },
    },
    {
      title: "Log out",
      data: { id: "logout" },
      icon: { icon: "right-from-bracket", pack: "fa" },
    },
  ];

  // Chatbot dropdown options
  botList: any[] | undefined = [];
  selectedBot: any | undefined;

  languageMenuItems: MenuItem[];

  private languageSubscription: Subscription;
  curLanguage: Languages;

  // PROCESSING BOOLEANS
  fileUploadProcessing: boolean;
  webpageCrawlProcessing: boolean;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    private dialogService: DialogService,
    private languages: LanguagesService,
    private translate: TranslateService,
    private authService: AuthService,
    private chatbotService: ChatbotService,
    private fileService: FileUploaderService,
    private webpageService: WebsiteCrawlerService,
    private qaService: QAndAService,
  ) {}

  ngOnInit() {
    this.chatbotService.curBot$.subscribe({
      next:(bot) => {
        if (bot) {
          this.botList = this.chatbotService.getBotList();
          this.selectedBot = this.chatbotService.getCurBot();
        } 
      }
    })

    // Processing booleans
    this.fileService.uploading$.subscribe({
      next: (uploading) => {
        this.fileUploadProcessing = uploading;
      },
    });
    this.webpageService.crawlingStatus$.subscribe({
      next: (crawling) => {
        this.webpageCrawlProcessing = crawling;
      }
    })

    // Get current user
    this.userService
      .getCurrentUser()
      .subscribe((curUser) => (this.user = curUser));

    // Start subscription on user updates
    this.userService.user$.subscribe((curUser) => {
      this.user = curUser;
    });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.menuItemSubscription = this.menuService
      .onItemClick()
      .subscribe((event) => {
        if (event.item.data?.id === "profile") {
          this.showProfile();
        } else if (event.item.data?.id === "logout") {
          this.authService.signOut();
        }
      });

    forkJoin([
      this.translate.get("choose_language"),
      this.translate.get("profile_menu.profile"),
      this.translate.get("profile_menu.log_out"),
    ]).subscribe((values: string[]) => {
      this.languageMenuItems = [
        {
          label: values[0],
          items: [
            {
              label: "English",
              command: () => {
                this.languages.setLanguage(Languages.English);
              },
            },
            {
              label: "Français",
              command: () => {
                this.languages.setLanguage(Languages.French);
              },
            },
          ],
        },
      ];
      this.userMenu = [
        {
          title: values[1],
          data: { id: "profile" },
          icon: { icon: "user", pack: "fa" },
        },
        {
          title: values[2],
          data: { id: "logout" },
          icon: { icon: "right-from-bracket", pack: "fa" },
        },
      ];
    });

    // On language change
    this.translate.onLangChange.subscribe((event) => {
      let label = this.translate.instant("choose_language");
      this.languageMenuItems[0].label = label;
      this.userMenu[0].title = this.translate.instant("profile_menu.profile");
      this.userMenu[1].title = this.translate.instant("profile_menu.log_out");
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.menuItemSubscription.unsubscribe();
  }

  processBotChangeEvent(event) {    
    // Initiate bot switch
    this.chatbotService.switchBot(event.value);
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.router.navigate(["/pages/dashboard"]);
    return false;
  }

  showProfile() {
    const ref = this.dialogService.open(ProfileComponent, {
      data: {
        user: this.user,
      },
      header: this.translate.instant("profile.header"),
    });
  }
}
